@import "../../variables.scss";

.fullReportCont {
    height: 100vh;
    color: $main;
    min-width: 300px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $default;
    overflow: hidden;
    .backgroundTransition{
        width: 100%;
        min-height: 100vh;
        background: $default;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 0;
        opacity: 1;
        -webkit-transition: opacity 0.5s linear;
        transition: opacity 0.5s linear;
    }
    .lastUpdated{
        position: absolute;
        top: 5px;
        width: 100%;
        text-align: center;
        color: $subtle;
    }
    .fullReportFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        max-height: 700px;
        z-index: 1;
        opacity: 0;
        transform: translate(22px);
        -webkit-transition: opacity 0.3s linear, transform, 0.3s linear;
        transition: opacity 0.3s linear, transform, 0.3s linear;
        .header {
            position: relative;
            margin-top: 44px;
            text-align: center;
            span {
                position: absolute;
                left: 22px;
                top: 0;
                width: 23px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .mainInfo {
            margin: 22px;
            height: 35%;
            display: flex;
            justify-content: space-between;
            max-width: 656px;
            .infoColumnLeft {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-width: 140px;
                h4 {
                    color: $subtle;
                    font-weight: bold;
                    text-decoration: underline;
                }
                .temp {
                    display: block;
                    font-size: $size2;
                    font-weight: bold;
                }
                .highLow {
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        margin: 11px 11px 11px 0;
                        &:nth-child(2) {
                            color: $subtle;
                        }
                        svg {
                            color: $subtle;
                        }
                    }
                }

                .description {
                    color: $subtle;
                }
            }
            .divider {
                height: 100%;
            }
            .infoColumnRight {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                text-align: left;
                max-width: 160px;

                div {
                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6) {
                        text-align: right;
                    }
                    span {
                        display: block;
                        &:first-child {
                            margin-bottom: 10px;
                            color: $subtle;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .hourly {
            padding: 0 22px;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid $subtle;
            border-bottom: 1px solid $subtle;
            font-size: $smSize2;
            div {
                min-width: 12%;
                margin: 0 5px;
                text-align: center;
                img{
                    width: 35px;
                }
                span {
                    line-height: 1rem;
                    display: block;
                    width: 100%;
                    &:first-child {
                        color: $subtle;
                        margin-top: 10px;
                    }
                    &:last-child{
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .threeDay {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 22px;
            position: relative;
            .row {
                margin: 11px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                text-align: center;
                span {
                    &:nth-child(2) {
                        color: $subtle;
                    }
                }

                .icon {
                    img {
                        width: 35px;
                    }
                }
                .highLow {
                    display: flex;
                    flex-flow: wrap;
                    justify-content: center;

                    span {
                        margin-right: 11px;
                    }
                }
            }
        }
        @media screen and (min-width: 350px) {
            .threeDay {
                .weekday {
                    position: absolute;
                    left: 0;
                }
                .icon {
                    margin: 0 auto;
                }
                .highLow {
                    position: absolute;
                    display: block;
                    right: 0;
                }
            }
        }
        @media screen and (min-width: $widthSm) {
            .header {
                max-width: 656px;
                margin-right: auto;
                margin-left: auto;
                span {
                    left: -100px;
                }
            }
            .mainInfo {
                justify-content: center;
                margin: 22px auto;
                width: 100%;
                .divider {
                    border-right: 1px solid $main;
                    margin: 0 44px;
                }
            }

            .hourly {
                max-width: 700px;
                width: 100%;
                margin: 0 auto;
                font-size: $smSize1;
            }
        }
        @media screen and (min-width: $widthMs) {
            .threeDay {
                flex-direction: row;
                justify-content: space-between;
                .row {
                    padding: 11px 22px;
                    border-radius: 10px;
                    flex-direction: column;
                    align-items: center;
                    background-color: #00000045;
                    min-width: 170px;
                    .icon {
                        margin: 11px 0;
                    }
                    .weekday {
                        position: static;
                    }
                    .highLow {
                        position: static;
                    }
                }
            }
        }
        @media screen and (min-width: $widthMd) {
            .threeDay {
                width: 700px;
                margin: 0 auto;
            }
        }
    }
}
