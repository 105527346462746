//font styles
$raleway : 'Raleway', sans-serif;

// larger font sizes

$size1 : 1.5rem;
$size2 : 2rem;

//small font size

$smSize1 : 1rem;
$smSize2 : 0.8rem;

//font colors

$main : white;
$subtle : #ffffff8c;
$bold : #000000a1;

//background colors

$default : linear-gradient(to top right, #5900ff 0%, #ffffff 100%);


//screen widths
$widthXsm : 350px;
$widthSm : 400px;
$widthMs : 600px;
$widthMd : 700px;

