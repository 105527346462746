@import "../../variables.scss";

.homeCont {
    display: flex;
    justify-content: center;
    height: 100%;
    .middleFlex {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-top: 66px;

        h1 {
            font-size: $size2;
            margin-bottom: 44px;
        }
        .searchBar {
            position: relative;
            margin: 66px 0;
            input {
                font-size: $size1;
                padding: 10px 15px;
                border: none;
                outline: none;
                border-radius: 10px;
                width: 100%;
                margin: 0 auto;
                display: block;
                box-sizing: border-box;
                position: relative;
                z-index: 10;
            }
            button {
                position: absolute;
                right: -60px;
                border: none;
                outline: none;
                height: 100%;
                width: 48px;
                border-radius: 10px;
                right: 0;
                top: 0;
                background-color: #e2e2e2;
                z-index: 10;
                &:hover {
                    cursor: pointer;
                }
            }
            .credit {
                position: absolute;
                width: 100%;
                text-align: center;
                margin-top: 11px;
            }
            .autoCompleteCont {
                background-color: white;
                position: absolute;
                box-sizing: border-box;
                width: 100%;
                top: 30px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                z-index: 5;

                .highlight {
                    background-color: #cccccc;
                }
                .noLocations{
                    padding: 11px;
                    margin-top: 22px;
                    display: block;
                }
                div {
                    padding: 11px;
                    &:first-child{
                        margin-top: 22px;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    &:last-child {
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                }
            }
        }
        .recents {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            width: 80%;
            .recentCont {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #ffffff82;
                border-radius: 20px;
                padding: 22px 11px;
                min-width: 200px;
                margin: 22px;
                position: relative;
                &:hover {
                    cursor: pointer;
                }
                .lastUpdated{
                    position: absolute;
                    bottom: -18px;
                    font-size: $smSize2;
                    color: $subtle;
                    width: 120%;
                    text-align: center;
                }
                .name {
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
    }
    @media screen and (min-width: $widthSm) {
        .middleFlex {
            .searchBar {
                width: 350px;
            }
        }
    }
}
