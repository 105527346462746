.modalCont {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    line-height: 2rem;
    font-weight: bold;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 20;
    .infoFlex{
        background-color: white;
        padding: 22px;
    }
    button {
        margin: 22px;
        padding: 5px 15px;
        border: none;
        background-color: #797979;
        color: white;
        border-radius: 5px;
        outline: none;
        box-shadow: 1px 7px 18px -5px grey;
        cursor: pointer;
        &:first-child {
            background-color: #009c00;
        }
    }
}